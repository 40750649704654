import CryptoJS from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";

const isBrowser = () => typeof window !== "undefined";

const getUserData = () => {
  if (
    isBrowser() &&
    sessionStorage.getItem("_save") &&
    localStorage.getItem("@user")
  ) {
    const encrypt = localStorage.getItem("@user");
    const bytes = CryptoJS.decrypt(encrypt, process.env.USER_KEY || "");
    const user = JSON.parse(bytes.toString(CryptoENC));
    return user;
  } else if (
    isBrowser() &&
    localStorage.getItem("@remember") &&
    localStorage.getItem("@user")
  ) {
    const encrypt = localStorage.getItem("@user");
    const bytes = CryptoJS.decrypt(encrypt, process.env.USER_KEY || "");
    const user = JSON.parse(bytes.toString(CryptoENC));
    return user;
  } else {
    return null;
  }
};

const saveUserData = (user) => {
  const encryptText = CryptoJS.encrypt(
    JSON.stringify(user),
    process.env.USER_KEY || ""
  ).toString();

  localStorage.setItem("@user", encryptText);
};

const isLoggedIn = () => {
  if (localStorage.getItem("_id_")) {
    return true;
  } else {
    return false;
  }
};

export { getUserData, saveUserData, isLoggedIn };
