import axios from "axios";

export const API = axios.create({
  baseURL: "https://dashboard.thunderstake.io/api/1.0.0",
  responseType: "json",
});
API.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("_id_");
  config.headers.Authorization = token ? `Token ${token}` : "";
  config.headers["Content-Type"] = "application/json";

  // config.headers["withCredentials"] = true;
  

  return config;
});
