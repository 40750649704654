import React, { useState, useEffect, useRef } from "react";
import { API } from "../config/api";

import { navigate } from "gatsby";
import { getUserData, isLoggedIn } from "../utils/userData";

const defaultValues = {
  user: null,
  isLogged: false,
  setUser: () => {},
  logout: () => {},
  setIsLoggedIn: () => {},
  dashboardLink: null,
};

export const AuthContext = React.createContext(defaultValues);

// const isBrowser = typeof window !== `undefined`;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLogged, setIsLoggedIn] = useState(false);
  // const [loading, setLoading] = useState(true);

  const requestUserData = async (e) => {
    if (!user) return null;
    try {
      const userRes = await API.get(`/user/${user.id}`);
      if (userRes.data) {
        setUser(userRes.data); 
      }
      setIsLoggedIn(true);
      return userRes;
    } catch (err) {
      setIsLoggedIn(false);
      localStorage.clear();
      return null;
    }
  }

  useEffect(() => {
    
    const logOutRequest = window.location.search.indexOf('logout');

    if (logOutRequest) {
      logout();
    } else {
      const u = getUserData();
      if (isLoggedIn() && u) {
        setIsLoggedIn(true);
        setUser(u);
      } else {
        localStorage.clear();
      }
      if (u) {
        requestUserData();
      }
    }
  }, []);

  const logout = () => {
    localStorage.clear();
    setUser(null);
    setIsLoggedIn(false);
    //navigate("/");
  };

  const interval = useRef(null);
  useEffect(() => {
    clearInterval(interval.current);
    interval.current = setInterval(
      requestUserData,
      10000
    );
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLogged,
        setUser,
        setIsLoggedIn,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
